export const CATEGORY_LIMIT = 6;
export const CONTENT_LIMIT = 24;
export const SIMILAR_CONTENT_LIMIT = 6;
export const ACTORS_LIMIT = 14;
export const HERO_PLAYER_ITEM_LIMIT = 10;
export const POSITION_UPDATE_INTERVAL = 30;
export const ENTITLEMENT_UPDATE_INTERVAL = 60;
export const REDIRECT_DELAY = 5000;
export const AVAILABLE_UNTIL_DAYS = 14;
export const USER_SETTINGS_POLL_INTERVAL = 30 * 60 * 1000; // 30 minutes

export const UNSUPPORTED_BROWSERS = ['INTERNET_EXPLORER'];

export const PIN_PATTERN = '[0-9]{4}';

export const LARGE_REGION_ID = 22;
export const SMALL_REGION_ID = 23;
export const SERVICES_REGION_ID = 69;
export const FRONTPAGE_REGIONID = 91;
export const HERO_BANNER_CATEGORY = 14835;

export const BANNER_CAROUSEL_AUTOPLAY_SPEED = 3000;
export const BANNER_CAROUSEL_SIZES = {
	SMALL: 150,
	MEDIUM: 200,
	LARGE: 300,
};

export const LANGUAGES = [
	{
		value: 'IS',
		label: 'Íslenska',
	},
	{
		value: 'EN',
		label: 'Enska',
	},
];

export const DEFAULT_LANGUAGE = 'is';
export const DEFAULT_VOLUME = 1;
export const DEFAULT_IMAGE = '/images/logo.png';

export const PROFILE_CAP = 5;

export const NAVIGATION_VERSION = 'eyeliner';
export const SLUG_PREFIX = 'eyeliner';
export const SLUG_HOME = 'eyeliner-home';
// export const ALLOWED_SLUGS = ['movies', 'children', 'sports', 'episodes', 'rent', 'catchup', 'stod2plus'];
export const ALLOWED_SLUGS = ['movies', 'children', 'sports', 'episodes', 'rent', 'catchup', 'stod2plus', 'siminn-premium'];
export const SPORT_CHANNELS = ['Stod2Sport_HD', 'Stod2Sport2_HD', 'Stod2Sport3_HD', 'Stod2Sport4_HD', 'Stod2Sport5_HD', 'Stod2Sport6_HD', 'PepsiMax_1', 'PepsiMax_2', 'PepsiMax_3', 'PepsiMax_4'];
export const AD_TYPE_SPORT_WORLD = 'sports-world';
export const ENABLE_HERO_TRAILERS = true; // todo move these contants to online config (AWS Appconfig or ConfigCat)
export const ENABLE_HERO_BANNER = true; // todo move these contants to online config (AWS Appconfig or ConfigCat)

export const ALLOWED_CHANNELS = ['stod2', 'stod3', 'sport', 'sport2', 'sport3', 'sport4', 'golfstodin', 'esport', 'vodasport'];
export const ALLOWED_LIVE_CHANNELS = ['sport', 'sport2', 'sport3', 'sport4', 'golfstodin', 'esport', 'vodasport'] as const;
export type LiveChannels = typeof ALLOWED_LIVE_CHANNELS;
